<template>
  <q-dialog v-model="this.noteModalVisible" transition-show="rotate" transition-hide="rotate">
    <q-card class="bg-teal text-white" style="width: 100%">
      <q-card-section class="q-pt-none">
        <q-form
            class="padding-sm"
            @submit="submitNote"
            @reset="closeNoteModal">
          <q-item>
            <q-input
                filled
                v-model="this.note"
                label="Note"
                type="textarea"
            ></q-input>
          </q-item>
        </q-form>
      </q-card-section>

      <q-card-actions align="right" class="bg-white text-teal">
        <q-btn label="Cancel" @click="closeNoteModal()" type="submit" color="secondary"></q-btn>
        <q-btn label="Done" @click="submitNote()" type="submit" color="primary"></q-btn>
      </q-card-actions>

    </q-card>
  </q-dialog>
      <q-list bordered>
        <q-item>
          <q-item-section class="text-h5">Case Notes</q-item-section>
        </q-item>
        <q-item>
          <q-item-section>
            <q-btn label="Add Note" @click="addNote()" type="submit" color="primary"></q-btn>
          </q-item-section>
        </q-item>
          <q-item v-for="(note_field,index) in this.notes" :key="index">
            <q-item-section bordered class="border">
              <p>
                <strong>Author: {{ note_field.user.full_name }} </strong>
              </p>
              <p>
                {{note_field.content}}
              </p>
              <p>Date: {{note_field.created_at}}</p>
            </q-item-section>
          </q-item>
      </q-list>
</template>


<script>

import {useRouter} from "vue-router";
import axios from "axios";
import {Notify} from "quasar";

export default {
  name: 'CaseNotes',
  props: [
      'returned_cases',
      'reload'
  ],
  data(){
    return {
      note: '',
      notes : [],
      noteModalVisible : false,
    }
  },
  setup(){

  },
  methods:{
    addNote(){
      this.noteModalVisible = true;
    },
    closeNoteModal(){
      this.noteModalVisible = false;
      this.getCaseNotes();
    },
    submitNote(){
      let notable_id = this.returned_cases.id;
      let notable_type = 'App\\Models\\Docket';
      let content = this.note;
      if(content.length < 1){
        Notify.create({
          progress: true,
          timeout: 1000,
          position: 'center',
          color: 'red-4',
          textColor: 'white',
          icon: 'cloud_done',
          message: 'Note cannot be empty !! '
        })
        return;
      }
      axios.post(this.$store.getters.url+'/api/api-notes',{
        notable_id: notable_id,
        notable_type: notable_type,
        content: content
      })
          .then(() => {
            Notify.create({
              progress: true,
              timeout: 1000,
              position: 'center',
              color: 'green-4',
              textColor: 'white',
              icon: 'cloud_done',
              message: 'Note Added !! '
            })
            this.reload_notes = 1;
            this.changeTab(3);
          })
          .catch(() => {
          }).finally(() => {
        this.note = null;
        this.closeNoteModal();
      });
    },
    showNoteModal(){
      this.noteModalVisible = true;
    },
    getCaseNotes(){
      const router = useRouter()
      let token = this.$store.getters.token;
      if( token ){
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }else{
        router.push({ name: "login"})
      }
      axios.get(this.$store.getters.url+'/api/api-notes?docket_id='+this.$route.params.id)
          .then((res) => {
             this.notes = res.data
          })
          .catch(() => {
          }).finally(() => {
      });
    },
  },
  watch: {
    reload(){
      this.getCaseNotes();
    }
  },
  mounted(){
      this.getCaseNotes();
  }
}
</script>
