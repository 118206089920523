<template>
  <!--
  Forked from:
  https://quasar.dev/vue-components/list-and-list-items#example--left-avatar-thumbnail-qitemsection
-->
  <div id="q-app" style="min-height: 100vh;">
    <div class="q-pa-md" style="max-width: 350px">
      <div :key="render_table">
        <div v-if="this.returned_cases" class="text-h5 q-mb-md">You have {{filtered_cases.length}} {{this.docket_description}}s assigned/referred to you</div>
        <div class="q-m-md">
          <label>Search</label>
          <q-input class="q-card--bordered q-m-md q-pl-md" v-model="searchTerm" placeholder="" />
        </div>
        <div class="q-m-md">
          <label>Filter</label>
          <q-select v-model="this.selectedDocketCategory"
                    :options="this.docket_categories"
                    emit-value
                    :label="this.docket_description+' Category'"
                    option-label="name"
          />
        </div>
        <div class="q-mb-md">
          <q-checkbox
              left-label
              v-model="includeClosed"
              label="Include closed"
              :true-value="true"
              :false-value="false"
              checked-icon="task_alt"
              unchecked-icon="highlight_off"
          ></q-checkbox>
          <div>
            <q-btn type="button" @click="resetFilters()" class="text-sm q-mb-md">Reset Filters</q-btn>
          </div>
        </div>
        <div v-for="(reported_case,index) in filtered_cases" :key="index">
          <q-list bordered class="clickable">
            <q-item :to="'/case/'+reported_case.id">
              <q-item-section class="text-h5" :id="index">{{reported_case.reference}} - {{reported_case.description}}</q-item-section>
            </q-item>
            <q-item :to="'/case/'+reported_case.id">
              <q-item-section>
                <span v-if="reported_case.created_by_user">
                Logged by: {{reported_case.created_by_user.full_name }}
              </span>
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                {{this.docket_description}} : {{reported_case.reference}}
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                {{this.docket_description}} Status: {{reported_case.status.name}}
              </q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                {{this.docket_description}} Stage: {{reported_case.stage.name}}
              </q-item-section>
            </q-item>
            <q-item>
              <div class="flex">
                <div class="flex q-mt-sm flex-row">
                  {{this.docket_description}} Categories :
                </div>
                <div class="flex flex-row" v-for="category in reported_case.categories" :key="category">
                  <q-chip siz class="q-p-sm" color="primary" text-color="white">
                    {{ category.name}}
                  </q-chip>
                </div>
              </div>
            </q-item>
            <q-item>
              <q-item-section class="text-sm">
                {{this.five_description}} : {{reported_case.company_level_five.name}}
              </q-item-section>
            </q-item>
            <q-item  :to="'/case/'+reported_case.id">
              <q-item-section :id="index">Log date: {{reported_case.created_at}}</q-item-section>
            </q-item>
          </q-list>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import axios from 'axios'
import {useQuasar} from "quasar";

export default {
  name: 'OpenCasesPage',
  setup(){
    return {
    }
  },
  data(){
    return {
      selectedDocketCategory: null,
      searchTerm: '',
      includeClosed : false,
      returned_cases : [],
      render_table : null,
      current_user : null,
      users : null
    }
  },
  computed: {
    filtered_cases() {
      return this.returned_cases.filter((reported_case) => {
        const matchesSearchTerm = (
            (reported_case.description && reported_case.description.toLowerCase().includes(this.searchTerm)) ||
            (reported_case.title && reported_case.title.toLowerCase().includes(this.searchTerm)) ||
            (reported_case.status && reported_case.status.name.toLowerCase().includes(this.searchTerm)) ||
            (reported_case.stage && reported_case.stage.name.toLowerCase().includes(this.searchTerm)) ||
            (reported_case.company_level_five && reported_case.company_level_five.name.toLowerCase().includes(this.searchTerm)) ||
            (reported_case.reference && reported_case.reference.toLowerCase().includes(this.searchTerm))
        );

        const matchesCategory = this.selectedDocketCategory
            ? reported_case.categories.some(
                (category) => category.id === this.selectedDocketCategory.id
            )
            : true;

        const matchesStatus = !reported_case.status?.name?.toLowerCase().includes('losed'); // Exclude closed cases

        if (this.includeClosed) {
          return matchesSearchTerm && matchesCategory;
        } else {
          return matchesSearchTerm && matchesCategory && matchesStatus;
        }
      });
    },
    url: function(){
      return this.$store.getters.url;
    },
    user_id : function(){
      return JSON.parse( this.$store.getters.user_id );
    },
    level_ones : function(){
      return this.$store.getters.level_ones;
    },
    one_description: function(){
      return this.$store.getters.one_description;
    },
    current_level_one: function(){
      let one = this.$store.getters.current_level_one;
      return one;
    },
    level_twos : function(){
      return this.$store.getters.level_twos;
    },
    two_description: function(){
      return this.$store.getters.two_description;
    },
    current_level_two: function(){
      let two = this.$store.getters.current_level_two;
      return two;
    },
    level_threes : function(){
      return this.$store.getters.level_threes;
    },
    three_description: function(){
      return this.$store.getters.three_description;
    },
    docket_categories : function(){
      return this.$store.getters.docket_categories;
    },
    current_level_three: function(){
      let three = this.$store.getters.current_level_three;
      return three;
    },
    level_fours : function(){
      return this.$store.getters.level_fours;
    },
    four_description: function(){
      return this.$store.getters.four_description;
    },
    current_level_four: function(){
      let four = this.$store.getters.current_level_four;
      return four;
    },
    level_fives : function(){
      return this.$store.getters.level_fives;
    },
    current_level_five: function(){
      let five = this.$store.getters.current_level_five;
      return five;
    },
    five_description: function(){
      return this.$store.getters.five_description;
    },
    token: function () {
      return this.$store.getters.token;
    },
    docket_description: function(){
      return this.$store.getters.docket_description;
    }
  },
  mounted(){
    this.syncData();
  },
  methods : {
    resetFilters(){
      this.searchTerm = '';
      this.selectedTaskCategory = null;
      this.includeClosed = false;
    },
    getUsers(){
      axios.get(this.$store.getters.url+'/api/api-users',{
        headers :{
          Authorization : 'Bearer '+this.token
        },
      })
          .then((res) => {
            this.users = res.data.data;
            let user_id = JSON.parse(localStorage.getItem('user_id'));
            this.users.forEach((user) => {
              if(user.id === user_id){
                this.current_user = user.name;
              }
            });
          })
          .catch(() => {
          }).finally(() => {
      });
    },
    searchCases(){
       this.returned_cases.filter((reported_case) => {
         return reported_case.description.includes(this.searchTerm);
       });
    },
    async syncData(){
      const $q = useQuasar();
      axios.get(this.$store.getters.url+'/api/api-dockets',{
        headers:{
          Authorization : 'Bearer '+this.token
        },
        params:{
          owner_type : 'InvolvingMe',
        }
      })
          .then((res) => {
            // eslint-disable-next-line no-unreachable
            $q.notify({
              color: 'green-4',
              textColor: 'white',
              icon: 'cloud_done',
              message: this.docket_description +'s Retrieved !! '
            })
            this.returned_cases = res.data;
            this.render_table = true;
            console.log(this.returned_cases);
          })
          .catch((error) => {
            console.log(error)
            $q.notify({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Error retrieving tasks: '+error
            })
          }).finally(() => {
        //Perform action in always
      });
    },
  }
}
</script>
