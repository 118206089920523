<template>
  <q-page class="flex flex-center">
    <div style="min-height: 100vh;">
      <div class="q-mt-md q-mr-sm q-pa-md" style="max-width: 400px">
        <div v-if="!url">
          <q-item-label class="text-h6">
            You need to link your system first
          </q-item-label>
          <div class="q-mt-md q-mr-sm" v-if="scanner_active">
            <q-btn @click="scanner_active = false">
              Stop Scanner
            </q-btn>
            <qrcode-stream @detect="onDetect($event)" @decode="onDecode($event)" @init="onInit"></qrcode-stream>
          </div>
          <div class="q-mt-md q-mr-sm" v-if="!scanner_active">
            <q-item-label>
              Enter the URL of the system or click on the button to scan QR
            </q-item-label>
            <q-input @blur="updateUrl()" @change="updateUrl()" v-model="input_url" label="URL" filled></q-input>
            <q-btn class="q-mt-md q-mr-sm" @click="scanner_active = true">
              Start Scanner
            </q-btn>
          </div>
        </div>
        <div class="q-mt-md q-mr-sm" v-if="this.url">
          <q-img
              :src="logo_url"
              :height="'300px'"
              :fit="'contain'"
              style="object-fit: contain;"
          />
        </div>
        <q-form
            v-if="this.url"
            method="post" @submit.prevent="onSubmit"
            @reset="onReset"
            class="q-gutter-md"
        >

          <q-item-label>
            Cellphone
          </q-item-label>
          <q-input
              v-model="this.cellphone"
              v-maska data-maska="+## ## ### ####"
              type="text"
              name="cellphone"
              id="cellphone"
              class="dark:bg-gray-700 dark:text-gray-100 block w-full rounded-md border-0 py-1.5 dark:text-gray-100 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />

          <q-input
              filled
              :type="isPwd ? 'password' : 'text'"
              hint="Please type password"
              name="password"
              v-model="this.password"
              label="Password"
              lazy-rules
              aria-autocomplete="current-password"
              :rules="[ val => val && val.length > 0 || 'Please type something']"
          >
              <template v-slot:append>
                <q-icon
                    :name="isPwd ? 'visibility_off' : 'visibility'"
                    class="cursor-pointer"
                    @click="isPwd = !isPwd"
                ></q-icon>
              </template>
          </q-input>
          <div v-if="!has_accepted">
            <p>
              I accept the <span class="text-primary" style="pointer:click" @click="this.showToc()">license and terms</span>
            </p>
          <q-toggle v-model="this.accept" >
          <q-dialog v-model="this.showAcceptModal">
              <div style="background-color:white;max-height: 80vh; overflow-y: auto;">
                <t-o-c-page @close="this.showAcceptModal = false" v-if="this.showAcceptModal"></t-o-c-page>
              </div>

          </q-dialog>
          </q-toggle>
          </div>
          <div class="row justify-center items-center" style="height: 100px;">
            <q-btn label="Submit" type="submit" color="primary"></q-btn>
          </div>
          <div>
<!--            APPVERSION HERE-->
            <p class="text-center text-caption text-weight-thin">Powered by Blackwidow V2.19</p>
          </div>
        </q-form>

      </div>
    </div>
  </q-page>
</template>

<style>
</style>

<script>
import { useQuasar } from 'quasar';
import axios from 'axios'
import {useRoute, useRouter} from 'vue-router' // <- import useRoute here
import { QrcodeStream } from 'vue-qrcode-reader'
import {vMaska} from "maska/vue";
import TOCPage from "@/views/TOC.vue";
export default {
  name: 'PageLogin',
  directives:{
    maska : vMaska
  },
  components: {
    QrcodeStream,
    TOCPage,
  },
  computed: {
    url: function(){
      return this.$store.getters.url;
    },
    token: function () {
      return this.$store.getters.token;
    },
  },
  setup () {
    let quasar = useQuasar()
    const router = useRouter();
    return { quasar, router }
  },
  data(){
    return {
      has_accepted : false,
      showAcceptModal : false,
      isPwd: true,
      logo_url : 'https://superadmin.blackwidow.org.za/customer_logo/specific',
      initial_url : null,
      input_url : null,
      scanner_active : false,
      cellphone : '+27',
      password : null,
      accept : false
    }
  },
  methods: {
    showToc(){
      this.showAcceptModal = true;
    },
    setupParameters() {
      const route = useRoute()
      if(route.params.url){
        this.input_url = route.params.url;
        this.$store.commit('UPDATE_URL',route.params.url);
      }
      this.has_accepted = this.$store.getters.user_terms;
      if(this.has_accepted){
        this.accept = true;
      }
    },
    onSubmit (evt) {
      console.log('@submit - do something here', evt)
      console.log(this.cellphone);
      console.log(this.password);
      console.log(this.accept);
      if (!this.accept) {
        this.quasar.notify({
          color: 'red-5',
          textColor: 'white',
          icon: 'warning',
          message: 'You need to accept the license and terms first'
        })
      }
      else {
        let submitData = {
          "cellphone" : this.cellphone.replace(/\s+/g, ''),
          "password" : this.password
        };
        let url = this.$store.getters.url;
        axios.post(url+'/api/login', submitData)
            .then((res) => {
              let token = res.data.token
              this.quasar.notify({
                color: 'green-4',
                textColor: 'white',
                icon: 'cloud_done',
                message: 'Logged in successfully !!'
              })
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
              this.$store.commit('UPDATE_TOKEN',token);
              this.$store.commit('UPDATE_USER_ID',res.data.user_id);
              this.$store.commit('UPDATE_USER_NAME',res.data.user.name);
              this.$store.commit('UPDATE_USER_TERMS',res.data.user.accepted_responder_terms);
              this.$store.commit('UPDATE_USER_SURNAME',res.data.user.surname);
              localStorage.setItem( 'app_status', JSON.stringify('logged_in') );
              this.router.push({ name: "home"})
            })
            .catch((error) => {
              this.quasar.notify({
                color: 'red-5',
                textColor: 'white',
                icon: 'warning',
                message: 'Log in Failed'+error
              })
            }).finally(() => {
          //Perform action in always
        });

      }
    },
    onReset () {
      this.cellphone = null
      this.password = null
      this.accept = false
    },
    resetUrl(){
      this.$store.commit('UPDATE_URL',null);
    },
    updateUrl(){
      this.$store.commit('UPDATE_URL',this.input_url);
    },
    onDetect(result) {
      this.$store.commit('UPDATE_URL',result[0].rawValue);
    },
    onDecode(result) {
      this.$store.commit('UPDATE_URL',result[0].rawValue);
    },
    onInit(promise) {
      promise.then(() => {
        console.log('QR code reader is ready!');
      }).catch(error => {
        if (error.name === 'NotAllowedError') {
          console.error('Permissions are not granted to use your camera');
        } else if (error.name === 'NotFoundError') {
          console.error('No camera on this device');
        }
        // Handle other initialization errors
      });
    }
  },
  mounted() {
    this.setupParameters();
    console.log(this.url);
  }
}
</script>
