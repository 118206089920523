<template>
  <!--
  Forked from:
  https://quasar.dev/vue-components/list-and-list-items#example--left-avatar-thumbnail-qitemsection
-->
  <div :key="this.page_status" id="q-app" style="min-height: 100vh;">
    <checklist-page
        @closeModal="closeChecklistPage()"
        v-if="this.selected_form"
        :task="returned_task"
        :key="selected_form"
        :show-modal="this.checklist_busy"
        v-model="checklist_busy"
        :form="this.selected_form">
    </checklist-page>
    <div class="q-pa-md" style="max-width: 100%">
      <div>
        <div class="q-mt-md q-mb-md">
          <q-btn @click="goBack()" color="black" class="text-white">
            <q-icon name="arrow_back" size="xs"></q-icon> &nbsp;Back
          </q-btn>
        </div>
<!--        Commented line-->
<!--        <h5>Status : {{ this.app_status.toUpperTask() }}</h5>-->
        <q-card class="my-card bg-gray text-gray">
          <q-card-section>
            <q-list bordered class="clickable">
              <q-item>
                <q-item-section class="text-h5">{{returned_task.id}} - {{returned_task.title}}</q-item-section>
                <q-item-section avatar>
                </q-item-section>
              </q-item>
<!--              <q-item v-if="Array.isArray(returned_task.allocatees)">-->
<!--                <q-item-section v-if="returned_task.allocatees.length > 0">-->
<!--                  Task Allocated To: {{returned_task.allocatees[0].name}} {{returned_task.allocatees[0].surname}}-->
<!--                </q-item-section>-->
<!--              </q-item>-->
              <q-item>
                <q-btn :href="
      'https://www.google.com/maps/dir/?api=1&destination='+this.returned_task.gps_lat+','+this.returned_task.gps_lng+'&travelmode=driving'" target="_blank" color="primary" label="Get Directions">
                </q-btn>
              </q-item>
              <q-item>
                <q-item-section>Task Description: {{returned_task.description}}</q-item-section>
              </q-item>
              <q-item>
                <q-item-section>Task Description: {{returned_task.description}}</q-item-section>
              </q-item>
              <q-item v-if="returned_task.assigned_to_user">
                <q-item-section v-if="returned_task.assigned_to_user.user">Task Assigned To: {{returned_task.assigned_to_user.user.full_name}}</q-item-section>
              </q-item>
              <q-item>
                <q-item-section>
                  Due Date: {{returned_task.due_date}}
                </q-item-section>
              </q-item>
              <q-item>
                <q-item-section v-if="returned_task.task_status">
                  Task Status: {{returned_task.task_status.description}}
                </q-item-section>
              </q-item>
              <q-item v-if="returned_task.task_category">
                <q-item-section>Task Category: {{returned_task.task_category.description}}</q-item-section>
              </q-item>
              <q-item >
                <q-item-section>Log date: {{returned_task.created_at}}</q-item-section>
              </q-item>
            </q-list>
          </q-card-section>
<!--          <q-card-section>-->
<!--            <q-list bordered class="clickable">-->
<!--              <q-item>-->
<!--                <q-item-section class="text-h5">-->
<!--                  Members-->
<!--                </q-item-section>-->
<!--                <q-item-section side class="text-h5">-->
<!--                  <q-btn round icon="add" @click="referModalVisible = true"></q-btn>-->
<!--                </q-item-section>-->
<!--              </q-item>-->
<!--              <q-item>-->
<!--                <q-item-section avatar>-->
<!--                  <p v-for="(member,index) in returned_task['members']" :key="index">-->
<!--                    {{member.type}} : {{member.member}}-->
<!--                    <span v-if="member.task_owner_type_id==='3'">-->
<!--                    <q-btn round icon="delete" @click="removeMember(member)"></q-btn>-->
<!--                  </span>-->
<!--                  </p>-->
<!--                </q-item-section>-->
<!--              </q-item>-->
<!--            </q-list>-->
<!--          </q-card-section>-->
        </q-card>
        <q-card class="bg-white text-white" style="width: 100%">
          <q-separator dark></q-separator>
          <q-list  bordered v-if="this.returned_task.task_status">
            <q-item v-if="!userAccepted && !this.returned_task.is_closed">
              <q-item-section v-if="checkForMe">
                <q-btn @click="acceptTask()"  color="secondary" icon="check" label="Accept Task"/>
              </q-item-section>
            </q-item>
            <q-item v-if="!this.returned_task.is_closed && userAccepted">
                <q-item-section v-for="(form,index) in this.returned_forms" :key="index">
                  <q-item-label class="bg-white text-black q-mt-md q-mb-md">
                    {{ this.calculateFilled(form) }}  / {{form.form_questions.length}} items in {{form.description}}
                  </q-item-label>
                  <q-btn @click="selectChecklist(form)"
                         v-if="this.isComplete(form)"
                         color="secondary" icon="check"
                         :label="form.description+' Complete'"/>
                  <q-btn @click="selectChecklist(form)"
                         v-if="!this.isComplete(form)"
                         color="primary" icon="rule"
                         :label="form.description"/>
                </q-item-section>
            </q-item>
            <q-item  v-if="!this.returned_task.is_closed && userAccepted">
              <q-item-section v-if="this.allChecklistsDone">
                <q-btn @click="closeTask()" color="secondary" icon="check" label="Complete Task"/>
              </q-item-section>
            </q-item>
            <q-item v-if="this.returned_task.is_closed && userAccepted">
              <q-item-section>
                <q-btn :to="'/'" color="primary" icon="check" label="Task is complete - Back to Home"/>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card>
      </div>
    </div>
  </div>
</template>

<style>
</style>

<script>
import axios from 'axios'
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
import {useQuasar} from "quasar";
import { Geolocation } from '@capacitor/geolocation'
import ChecklistPage from "@/components/ChecklistPage.vue";
import {Notify} from "quasar";

export default {
  name: 'ExecuteTasksPage',
  components: {
    ChecklistPage
  },
  setup(){
    return {
    }
  },
  computed:{
    current_owner: function(){
      return this.returned_task.assigned_to_user;
    },
    allChecklistsDone(){
      return this.calculateAllComplete();
    },
    token(){
      return this.$store.getters.token;
    },
    checkForMe(){
      if(parseInt(this.returned_task.assigned_to_user.user_id) !== parseInt(this.user_id)){
        return false;
      }else{
        return true;
      }
    },
    userAccepted(){
      if(this.returned_task && this.returned_task.assigned_to_user){
        console.log(this.returned_task.assigned_to_user.accepted);
        if((this.returned_task.assigned_to_user.accepted)){
          return true;
        }else{
          return false;
        }
      }else {
        return false;
      }
    },
    url(){
      return this.$store.getters.url;
    },
    user_id(){
      return this.$store.getters.user_id;
    }
  },
  data(){
    return {
      returned_forms : [],
      selected_form : null,
      checklist_busy : false,
      returned_task : Object,
      page_status : null,
      parameter_id : null,
      app_status : null,
      multiple : [],
      options : [],
      clockUsers : [],
      displayChecklists : false,
      preChecklists : Object,
      forms : Object,
      deliveryNote : Object,
      longitude : null,
      latitude : null
    }
  },
  mounted(){
     this.setupParameters();
     this.getData();
     this.getUsers();
     this.getLocation();
  },
  methods : {
    goBack(){
      this.$router.go('-1');
    },
    acceptTask(){
      let formData = new FormData();
      formData.append('user_id', this.user_id);
      formData.append('task_id', this.returned_task.id);
      let url = this.url + '/api/api-task-accept-participation';
      axios.post(url , formData, {
        headers: {
          Authorization: 'Bearer ' + this.token,
          "Content-Type": "multipart/form-data",
        }
      }).then(response => {
        if(response.data.success) {
          Notify.create({
            color: 'green-4',
            textColor: 'white',
            icon: 'cloud_done',
            message: 'Task Accepted'
          })
          this.returned_task.assigned_to_user.accepted = 1;
        }else{
          Notify.create({
            color: 'red-5',
            textColor: 'white',
            icon: 'warning',
            message: 'Task Acceptance Failed'
          })
        }
      }).catch(error => {
        console.error('There was an error!', error.response);
      });
    },
    rejectTask(){
      let formData = new FormData();
      formData.append('user_id', this.user_id);
      formData.append('task_id', this.returned_task.id);
      let url = this.url + '/api/api-task-reject-participation';
      axios.post(url , formData, {
        headers: {
          Authorization: 'Bearer ' + this.token,
          "Content-Type": "multipart/form-data",
        }
      }).then(response => {
        if(response.data.success) {
          Notify.create({
            color: 'green-4',
            textColor: 'white',
            icon: 'cloud_done',
            message: 'Task Rejected'
          })
          this.returned_task.assigned_to_user.accepted = 1;
        }else{
          Notify.create({
            color: 'red-5',
            textColor: 'white',
            icon: 'warning',
            message: 'Task Acceptance Failed'
          })
        }
      }).catch(error => {
        console.error('There was an error!', error.response);
      });
    },
    calculateAllComplete(){
      console.log("Checking all complete")
          let allComplete = true;
          this.returned_forms.forEach((form) => {
            console.log(this.isComplete(form))
            if(!this.isComplete(form)){
              allComplete = false;
            }
          });
          return allComplete;
    },
    closeTask(){
      let formData = new FormData();
      formData.append('task_id', this.returned_task.id);
      let url = this.url + '/api/api-task-complete';
      axios.post(url , formData, {
        headers: {
          Authorization: 'Bearer ' + this.token,
          "Content-Type": "multipart/form-data",
        }
      }).then(response => {
        if(response.data.type==='success') {
          Notify.create({
            color: 'green-4',
            textColor: 'white',
            icon: 'cloud_done',
            message: 'Task Completed'
          })
          setTimeout(() => {
            this.closeChecklistPage();
          }, 2000);
        }else{
          Notify.create({
            color: 'red-5',
            textColor: 'white',
            icon: 'warning',
            message: 'Task Completion Failed'
          })
        }
      }).catch(error => {
        console.error('There was an error!', error.response);
      });
    },
    closeChecklistPage(){
      this.checklist_busy = false;
      this.selected_form = null;
      window.location.reload();
    },
    isComplete(form){
      let filled = this.calculateFilled(form);
      if(filled === form.form_questions.length){
        return true;
      }else{
        return false;
      }
    },
    calculateFilled(form){
      let filled = 0;
      form.form_questions.forEach((item) => {
        if(item.form_answers){
          item.form_answers.forEach((answer) => {
            console.log(answer);
            if(parseInt(answer.submitted)===1){
              filled++;
            }
          });
        }
      });
      return filled;
    },
    selectChecklist(form){
      this.selected_form = null;
      this.selected_form = form;
      this.checklist_busy = true;
    },
    getUsers(){
      axios.get(this.$store.getters.url+'/api/api-users',{
        params:{
          user_id : this.user_id
        }
      })
          .then((res) => {
            this.users = res.data.data;
            this.users.forEach((user) => {
              if(user.name!==null){
                this.options.push(user.name);
              }
            });
          })
          .catch(() => {

          }).finally(() => {

      });
    },
    getLocation(){
      if (Geolocation.checkPermissions()) {
        Geolocation.getCurrentPosition({
          enableHighAccuracy: true
        }).then(newPosition => {
          localStorage.setItem('latitude', JSON.stringify(newPosition.coords.latitude));
          this.latitude = newPosition.coords.latitude;
          localStorage.setItem('longitude', JSON.stringify(newPosition.coords.longitude));
          this.longitude = newPosition.coords.longitude;
        })
      }
    },
    setupParameters() {
      const router = useRouter()
      let token = this.$store.getters.token;
      if( token ){
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }else{
        router.push({ name: "login"})
      }
      const route = useRoute()
      this.parameter_id = route.params.id;
    },
    startChecklists(form){
      this.selected_form = form;
      this.checklist_busy = true;
    },
    hideAllModals(){
      this.displayChecklists = false;
    },
    async getData() {
      const $q = useQuasar();
      axios.get(this.$store.getters.url+'/api/api-tasks/' + this.parameter_id,{
        headers: {
          'Authorization': 'Bearer ' + this.token
        }
      })
          .then((res) => {
            $q.notify({
              color: 'green-4',
              textColor: 'white',
              icon: 'cloud_done',
              message: 'Tasks Retrieved !! '
            })
            this.returned_task = res.data.task;
            console.log(this.returned_task);
            let forms = res.data.forms;
            forms.forEach((form) => {
              if(form.type==='checklist'){
                this.returned_forms.push (form);
              }
            });
            console.log(this.returned_task);
          })
          .catch((error) => {
            $q.notify({
              color: 'red-5',
              textColor: 'white',
              icon: 'warning',
              message: 'Error retrieving tasks: ' + error
            })
          }).finally(() => {
        this.forceRefresh();
        //Perform action in always
      });
    },
    forceRefresh(){
    },
  }
}
</script>
